import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { isArcos, isBisblick, isOsmia } from 'utils/contants';
import config from '../../../../../config';
import { apiCall } from '../../../../../crud/api.crud';
import useGetUserId from '../../../../../hooks/api/useGetUserId';
import { ButtonPrimary } from '../../../../molecules/Button';
import Conditional from '../../../Conditional';
import Modal from '../../../Modal';
import Loading from '../Loading/Loading';
import Form from './Forms';
import useStore from 'store/zustand/store';

const SubscribeButton = ({ activity, setIsSuscribed }) => {
  const { user, updateUser } = useStore((state) => state);

  const [policyAccept, setPolicyAccept] = useState(user.policy_accept);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [isOk, setIsOk] = useState(false);
  const { t } = useTranslation();
  const { title } = activity;
  const userId = useGetUserId();
  const forms = activity?.form?.fields;
  const [commentary, setCommentary] = useState('');
  const [formsSend, setFormsSend] = useState({});
  const [valid, setValid] = useState(false);
  const checkInputs = forms?.filter((form) => form.isRequired).map((form) => form.id);
  const [step, setStep] = useState(1);
  const [sentResponse, setSentResponse] = useState({});

  useEffect(() => {
    setValid(forms == null || checkInputs.length == 0);
  }, [forms]);

  // apiCall, 'activity/postulate', payload, 'POST';
  useEffect(() => {
    if (forms == null) return;
    let aux = true;
    for (const id of checkInputs) {
      if (typeof formsSend[id] === 'object' || formsSend[id]) {
        aux = true;
      } else {
        aux = false;
        break;
      }
    }
    setValid(aux);
  }, [formsSend]);

  const onChange = (e) => {
    setCommentary(e.target.value);
  };

  const save = async () => {
    setIsLoading(true);
    const aux = [];
    for (const form in formsSend) {
      aux.push({ id: form, value: typeof formsSend[form] === 'object' ? formsSend[form].value : formsSend[form] });
    }
    setStep(step + 1);
    setSentResponse({ commentary: commentary, response: aux });

    // postulation_reason, forms;
    // commentary, aux;
    const payload = {
      postulation_reason: commentary,
      user_id: userId,
      activity_id: activity.id,
      forms: aux,
      policy_accept: true,
    };

    await apiCall('activity/postulate', payload, 'POST').then(() => setIsOk(true));
    setIsLoading(false);
    updateUser({ policy_accept: true });
  };
  const handleCheckboxChange = (e) => {
    setPolicyAccept((prev) => !prev);
  };
  const close = () => {
    setIsOpen(false);
    setIsSuscribed(isOk);
    setStep(1);
  };

  const setAnswers = (name) => (value) => {
    setFormsSend({ ...formsSend, [name]: value });
  };

  const commentObigated = useMemo(() => config.activities.postulate.comment, []);

  const handleOpen = () => {
    setIsOpen(true);
    if (isArcos || (isBisblick && !!activity.guide_id)) {
      save();
    }
  };

  return (
    <>
      {!(activity.hidden_button && activity.url_donar) && (
        <ButtonPrimary onClick={handleOpen} type="button" className="button-reactour">
          {t(!!activity.guide_id ? 'button.enroll_me' : isOsmia ? t('button.enroll_me') : t('button.apply'))}
        </ButtonPrimary>
      )}

      <Conditional condition={isOpen}>
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
          <div>
            {loading && (
              <div className="modal-body">
                <Loading />
              </div>
            )}
            {loading ||
              (() => {
                switch (step) {
                  case 1:
                    return (
                      <div>
                        <div className="modal-header">
                          <h5 className="modal-title">
                            {t('home.apply_msg')} {title}
                          </h5>
                        </div>
                        <div className="modal-body">
                          <div className="form-group">
                            <label htmlFor="message-text" className="col-form-label">
                              {commentObigated && <span className="text-danger">*</span>}
                              {t('home.apply_msg1')}:
                            </label>
                            <textarea
                              style={commentObigated ? { border: commentary.length ? '' : '1px solid #dc3545' } : {}}
                              className="form-control"
                              id="message-text"
                              value={commentary}
                              onChange={onChange}
                            />
                          </div>
                          {forms &&
                            forms.map((form) => (
                              <Form key={`formApply${form.id}`} form={form} setAnswers={setAnswers} />
                            ))}
                          <small className="text-danger">Los campos con (*) son obligatorios.</small>

                          {!user.policy_accept && (
                            <div className="form-group p-3">
                              <label htmlFor="accept_privacy_policy">
                                <div className="border mt-2 p-2" style={{ maxHeight: '100px', overflowY: 'auto' }}>
                                  <input
                                    type="checkbox"
                                    name="accept_privacy_policy"
                                    id="accept_privacy_policy"
                                    value="1"
                                    checked={policyAccept}
                                    onChange={handleCheckboxChange}
                                    style={{ width: '15px', height: '15px', marginRight: '10px' }}
                                  />
                                  <span style={{ fontSize: '15px' }}>{t('activities.policy_accept')}</span>
                                </div>
                              </label>
                            </div>
                          )}
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" onClick={close} data-dismiss="modal">
                            {t('button.button_msg8')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={save}
                            disabled={(commentObigated && !commentary.length) || !valid || !policyAccept}
                          >
                            {config.profile_globant ? t('button.apply') : t('button.button_msg9')}
                          </button>
                        </div>
                      </div>
                    );
                  case 2:
                    return (
                      <>
                        {isArcos || isBisblick ? (
                          <ModalArcos t={t} title={title} activity={activity} close={close} />
                        ) : (
                          <ModalDefault t={t} title={title} forms={forms} sentResponse={sentResponse} close={close} />
                        )}
                      </>
                    );
                }
              })()}
          </div>
        </Modal>
      </Conditional>
    </>
  );
};

const ModalArcos = ({ t, title, activity, close }) => {
  return (
    <div>
      <div className="modal-header">
        <h5 className="modal-title">
          <strong>{t('activities.subscribed.title_arcos')}</strong>
        </h5>
      </div>
      <div className="modal-body">
        <div className="form-group">
          <h5>
            {t('activities.subscribed.description')} {title}
          </h5>
        </div>
        <span>
          {t('activities.subscribed.msg_arcos')}{' '}
          <Link to={`/detail/${activity?.id}`}>{t('activities.subscribed.see_more')}</Link>{' '}
          {t('activities.subscribed.or')} <Link to={`/actividad`}>{t('activities.subscribed.my_activities')}</Link>
        </span>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={close} data-dismiss="modal">
          {t('button.close')}
        </button>
      </div>
    </div>
  );
};

const ModalDefault = ({ t, title, forms, sentResponse, close }) => {
  return (
    <div>
      <div className="modal-header">
        <h5 className="modal-title">
          <strong>{t('activities.subscribed.title')}</strong>
        </h5>
      </div>
      <div className="modal-body">
        <div className="form-group">
          <h5>
            {t('activities.subscribed.description')} {title}
          </h5>
          <b className="text-info">{t('activities.subscribed.responses')} </b>
          {forms &&
            forms.map((form) => (
              <div key={`formApply${form.id}`} className="mb-2">
                <b className="text-muted">{form.name}</b>
                {sentResponse.response.map((res) => {
                  return res.id == form.id ? <b className="text-info"> {res.value}</b> : '';
                })}
              </div>
            ))}
          <br />
          <b className="text-muted">{t('activities.subscribed.comentary')} </b>
          <b className="text-info">{sentResponse.commentary}</b>
        </div>
        <span>{t('activities.subscribed.email_confirmation')}</span>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={close} data-dismiss="modal">
          {t('button.close')}
        </button>
      </div>
    </div>
  );
};

export default SubscribeButton;

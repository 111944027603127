import AnonymousUser from 'assets/anonymous_user.jpg';
import useOutsideClick from 'hooks/useOutsideClick';
import moment from 'moment';
import { FormEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { Link, useHistory, useParams } from 'react-router-dom';
import useStore, { User } from 'store/zustand/store';
import styled from 'styled-components';
import { STORAGE_URL } from 'utils/talents.util';
import { News } from '..';
import { apiCall } from '../../../../../crud/api.crud';
import Loading from '../../../../molecules/Loading';
import { isBaNorte } from 'utils/contants';

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const LeftContainer = styled.div`
  display: inline-block;
  width: 30%;
`;
const RigthContainer = styled.div`
  display: inline-block;
  width: 70%;
  margin-left: 50px;
`;

const Media = styled.img`
  object-fit: cover;
  width: 40px;
  height: 40px;
`;

const Button = styled.button`
  border: 1px solid;
  background: transparent;
  padding: 8px;
  border-radius: 0.5rem;
  font-weight: 500;
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

interface markup {
  __html: string;
}

interface NewsWithComments extends News {
  comments: { content: string; id: number; user_id: number; user: User; created_at: Date }[];
}

const CommunityDetail = () => {
  const history = useHistory();
  const { id } = useParams<{
    id: string;
  }>();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<NewsWithComments>();
  const [submitting, setSubmitting] = useState(false);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState('');
  const { user } = useStore((state) => state);
  const ref = useRef<HTMLFormElement>(null);
  const onClose = () => {
    if (show) setShow(false);
  };
  useOutsideClick(ref, onClose);

  const createMarkup = (setHtml: string): markup => ({ __html: setHtml });
  const setHtml = (html: string): ReactElement => <div dangerouslySetInnerHTML={createMarkup(html)} />;

  useEffect(() => {
    apiCall(`community/${id}`, null, 'GET')
      .then(({ data }) => {
        setData(data.data);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) return <Loading />;

  if (!data) {
    history.push('/community');
    return null;
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!user) return;
    setSubmitting(true);
    try {
      await apiCall(`community/${id}/comment`, { content: value }, 'POST');
      setData({
        ...data,
        comments: [
          { id: data.comments.length, content: value, user_id: user.id, user, created_at: new Date() },
          ...data.comments,
        ],
      });
      setValue('');
      setShow(false);
    } catch (error) {
      alert('error');
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <div className="container mt-2 px-0 pb-5">
      <div className="d-flex w-100 my-4 justify-content-start align-items-end">
        <button className="btn btn-link font-weight-bold text-primary" onClick={history.goBack}>
          <AiOutlineArrowLeft className="mr-2 mb-1"></AiOutlineArrowLeft> Volver
        </button>
      </div>
      <Container>
        <LeftContainer>
          {data.photo_path && (
            <figure className="figure">
              <img
                alt="img de community"
                src={`https://app.fonselp.com${data.photo_path}`}
                className="figure-img img-fluid rounded"
              />
            </figure>
          )}
          <p className="figure-caption">{data.short_description}</p>
          <span className="text-muted">
            <small>{data.created_at}</small>
          </span>
        </LeftContainer>
        <RigthContainer>
          <div>
            <h2  className={`font-weight-bold ${isBaNorte ? "text-black" : "text-info"}`}>{data.title}</h2>
            <div className="mt-3 text-justify">{setHtml(data.long_description)}</div>
          </div>
        </RigthContainer>
      </Container>
      <div className="mt-5">
        <h2 className="font-weight-bold">Comentarios</h2>
        <div className="d-flex align-items-start my-4">
          <Media
            src={user?.talent.image ? `${STORAGE_URL}/${user?.talent.image}` : AnonymousUser}
            className="rounded-circle mr-4"
          />
          <form onSubmit={handleSubmit} className="w-100" ref={ref}>
            <div className="position-relative">
              <textarea
                className="form-control"
                style={{ resize: 'none' }}
                placeholder="Escribir un comentario"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                onClick={() => setShow(true)}
                rows={show ? 6 : 1}
                required
              />
              {show && (
                <Button className="border-primary" disabled={submitting}>
                  Dejar comentario
                </Button>
              )}
            </div>
          </form>
        </div>

        {data.comments.map(({ id, user, content, created_at }) => (
          <div key={id} className="mt-4 pb-2 border-bottom">
            <div className="d-flex align-items-start">
              <Link to={`/profile/${user.id}`} target="_blank">
                <Media
                  src={user.talent.image ? `${STORAGE_URL}/${user?.talent.image}` : AnonymousUser}
                  className="rounded-circle mr-4"
                />
              </Link>
              <div>
                <p className="font-weight-bold m-0">
                  <Link to={`/profile/${user.id}`} className="text-dark" target="_blank">
                    {user.name} {user.surname}
                  </Link>
                </p>
                <p>{content}</p>
              </div>
            </div>
            <div className="text-right">
              <small>{moment(created_at).format('DD MMM YYYY')}</small>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommunityDetail;

import Ods from './Ods';

import config from '../../../../config';
import CatalogTalents from './CatalogTalents';
import ChangePassword from './ChangePassword';
import ExtraPersonalData from './ExtraPersonalData';
import MyPersonalData from './MyPersonalData';
import Skills from './Skills';
import useStore from '../../../../store/zustand/store';
import { apiCall } from 'crud/api.crud';
import { useEffect, useState } from 'react';
import Loading from 'components/molecules/Loading';
import ResultsChallenges from './Skills/Results';
import { isAda } from 'utils/talents.util';

const Profile = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { user, updateUser } = useStore((state) => state);

  useEffect(() => {
    apiCall(`user/${user.id}/profile`, null, 'GET')
      .then(({ data }) => {
        updateUser(data.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) return <Loading />;

  return (
    <>
      {config.catalogTalents && (
        <>
          <CatalogTalents />
          <Skills />
          {/* {!isAda && <ResultsChallenges />} */}
        </>
      )}
      {!config.profile_globant && <ChangePassword />}
      <MyPersonalData user={user} />
      {config.has_questions_of_interest && <ExtraPersonalData userId={user.id} />}
      {config.ods_profile && <Ods ods={user.sdgs} userId={user.id} />}
    </>
  );
};

export default Profile;
